import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './redux/store';
import { Toaster } from 'react-hot-toast';
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: window.REACT_APP_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration()],
  environment: window.REACT_APP_SENTRY_ENV,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: window.REACT_APP_SENTRY_SAMPLE_RATE,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
        <Toaster
          position="bottom-left"
        // toastOptions={{
        //   duration: 3000,
        //   iconTheme: {
        //     primary: "red",
        //     secondary: "white"
        //   },
        //   role: "status",
        //   ariaLive: "polite",
        //   style: {
        //     background: "green",
        //     color: "whitesmoke"
        //   }
        // }}
        />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
